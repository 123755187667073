<template>
  <div class="Cursor" :class="{'left': cursor.type == 'left', 'right': cursor.type == 'right', 'special-pointer': cursor.type == 'special-pointer', 'pointer': cursor.type == 'pointer', 'c3d': cursor.type == '3d', 'video play': cursor.type == 'videoPlay', 'video pause': cursor.type == 'videoPause', 'hide': cursor.type == 'none', 'default': cursor.type == 'default'}">
    <!-- v-show="cursor.type == 'special-pointer' || cursor.type == 'videoPlay' || cursor.type == 'videoPause'"-->
    <div class="progress-cursor">
      <div class="circle_wrapper circle_wrapper-right">
        <div class="circle_whole circle_right" id="circle_right"/>
      </div>
      <div class="circle_wrapper circle_wrapper-left">
        <div class="circle_whole circle_left" id="circle_left"/>
      </div>
      <div class="rounding"/>
      <div class="rounding" id="rounding-end"/>
    </div>    
    <div v-show="cursor.index != null && cursor.type != 'videoPlay' && cursor.type != 'videoPause'">
      <div v-show="cursor.index != '–'" class="cr-arrow-container">
        <div class="cr-arrow"/>
      </div>
      <p>{{cursor.index}}</p>
    </div>
    <svg v-show="cursor.type == '3d'" class="icon3d" viewBox="0 0 24 24">
      <path xmlns="http://www.w3.org/2000/svg" class="cls-1" d="M9,8a1,1,0,1,1,1,1A1,1,0,0,1,9,8Zm1-8C4.48,0,0,2.69,0,6v4c0,2.79,3.18,5.13,7.48,5.8v1l2.86-2.9L7.48,11v.8C4.27,11.26,1.68,9.86.58,8,2,5.67,5.65,4,10,4s8,1.67,9.42,4c-1.15,1.94-3.92,3.41-7.33,3.85v4c4.52-.58,7.91-3,7.91-5.87V6C20,2.69,15.52,0,10,0Z"/>
    </svg>
    <svg v-show="cursor.type == 'videoPlay'" class="play-button" width="100%" height="100%" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <path xmlns="http://www.w3.org/2000/svg" class="cls-1" d="M11,10 L18,13.74 18,22.28 11,26 M18,13.74 L26,18 26,18 18,22.28"/>
        <!-- todo animation
        <defs>
          <path id="12" d="M 11 10 L 17 10 L 17 26 L 11 26 M 20 10 L 26 10 L 26 26 L 20 26">
            <animate id="animation" begin="indefinite" attributeType="XML" attributeName="d" fill="freeze" from="M11,10 L17,10 17,26 11,26 M20,10 L26,10 26,26 20,26" to="M11,10 L18,13.74 18,22.28 11,26 M18,13.74 L26,18 26,18 18,22.28" dur="1s" keySplines=".4 0 1 1" repeatCount="indefinite"></animate>
          </path>
        </defs>
        <use xlink:href="#12" class="svg-shadow"></use>
        <use xlink:href="#12" class="svg-fill"></use>
        -->
    </svg>
    <svg v-show="cursor.type == 'videoPause'" class="play-button" width="100%" height="100%" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <path xmlns="http://www.w3.org/2000/svg" class="cls-1" d="M11,10 L17,10 17,26 11,26 M20,10 L26,10 26,26 20,26"/>
    </svg>
    <div class="Cursor-bg"/>
  </div>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex';

export default {
  computed: {
    ...mapGetters(['cursor']),
  },
  methods: {
    ...mapMutations(['changeCursor']),
  },
  mounted(){
    this.changeCursor('default');

    let cursor = document.getElementsByClassName("Cursor")[0];
    window.addEventListener("pointermove", (e) =>{
      cursor.style.top = e.clientY + "px";
      cursor.style.left = e.clientX + "px";
      cursor.classList.remove("hide");
    });
    document.body.addEventListener("pointerleave", () =>{
      cursor.classList.add("hide");
    });
    document.body.addEventListener("pointerenter", () =>{
      cursor.classList.remove("hide");
    });

    window.addEventListener("touchmove", (e) =>{
      cursor.classList.add("hide")
    });
    window.addEventListener("touchend", (e) =>{
      cursor.classList.add("hide");
    });
  }
}
</script>

<style>
.Cursor{ 
  --bg-color: var(--darkMuted);
  --fg-color: var(--lightVibrant);
  --size: 24px;
  width:  var(--size);
  height: var(--size);
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 10;
  transition: border-width .3s, width .3s, height .3s, transform .3s;
}

.Cursor.play{ 
  --size: 44px;
}

.Cursor-bg {
  position: absolute;
  transform: translate(-50%, -50%) rotate(45deg);
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border: calc(var(--size)/2 + 2px) solid var(--bg-color);
  background-color: transparent;
  border-radius: 50%;
  transition: border .3s ease, width .3s ease, height .3s ease, border-radius .3s ease;
  z-index: -2;
  filter: drop-shadow(0px 0px 4px #00000040);
}
.pointer .Cursor-bg {
  width: var(--size);
  height: var(--size);
  border: 4px solid var(--fg-color);
}
.special-pointer .Cursor-bg {
  border-color: transparent;
  transition: border .3s ease, width .3s ease, height.3s ease;
}
.right .Cursor-bg {
  border-radius: 50% 0 50% 50%;
}
.left .Cursor-bg {
  border-radius: 50% 50% 50% 0;
}
.play-button {
   fill: var(--fg-color);
}

.progress-cursor{
  opacity: 0;
  transition: opacity .3s;
}

.special-pointer .progress-cursor, .video .progress-cursor{
  opacity: 1;
}


.circle_wrapper{
  width: calc(50% + 4px);
  height: calc(100% + 8px);
  position: absolute;
  top: 0;
  overflow: hidden;
  margin: -4px;
}

.circle_wrapper-right{
  right: 0;
}

.circle_wrapper-left{
  left: 0;
}

.circle_whole {
  width: calc(200% - 8px);
  height: calc(100% - 8px);
  border: 4px solid transparent;
  border-radius: 50%;
  position: absolute;
  top: 0;
  transform: rotate(-135deg);
}

.circle_right {
  border-top: 4px solid var(--fg-color);
  border-right: 4px solid var(--fg-color);
  right: 0;
  transition: transform linear .3s;
}
.special-pointer .circle_right {
  animation: circleRight .3s linear forwards;
}

.circle_left {
  border-bottom: 4px solid var(--fg-color);
  border-left: 4px solid var(--fg-color);
  left: 0;
  transition: transform linear .3s;
}
.special-pointer .circle_left {
  animation: circleLeft .3s linear forwards;
}

@keyframes circleRight {
  0% {
    transform: rotate(-135deg);
  }
  50%,
  100% {
    transform: rotate(45deg);
  }
}

@keyframes circleLeft {
  0%,
  50% {
    transform: rotate(-135deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

.video .circle_right, .video .circle_left {
  animation: none
}
.cr-arrow-container{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  transform: rotate(45deg);
  /* cursor: none !important; */
}
.cr-arrow{
  width: calc(var(--size)/2);
  height: calc(var(--size)/2);
  position: absolute;
  top: 0;
  right: 0;
  margin: -5px;
  border-style: solid;
  border-width: 5px 5px 0px 0px;
  border-color: var(--fg-color);
  border-radius: 3px 100%;
  z-index: -1;
  transition: width .3s, height .3s;
  animation: arrowR .3s ease-out forwards;
}
.left .cr-arrow{
  top: unset;
  bottom: 0;
  left: 0;
  animation: arrowL .3s ease-out forwards;
  border-width: 0px 0px 5px 5px;
}
@keyframes arrowR {
  0%   {border-radius: 3px 100%;}
  100% {border-radius: 3px 7px;}
}
@keyframes arrowL {
  0%   {border-radius: 3px 100%;}
  100% {border-radius: 3px 7px;}
}
.left p{
  margin-left: 3px;
}
.right p{
  margin-left: -3px;
}
.icon3d {
  margin-top: 4px;
  margin-left: 3px;
}
.icon3d path{
  fill: var(--fg-color);
}
.hide{
  --size: 0;
  display: none;
}
.Cursor p{
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  margin: 0;
  margin-top: 1px;
  text-align: center;
  color: var(--fg-color);
  /* font-family: 'Poppins'; */
  font-weight: 900;
  transition: margin-left .3s;  
}
.rounding{
  background-color: var(--fg-color);
  width: 4px;
  height: 4px;
  top: calc(50% - 2px);
  left: calc(50% - 2px);
  border-radius: 50%;
  position: absolute;
  transform: rotate(0deg) translateY(calc(var(--size)/-2 - 2px));
  transition: transform .3s;
}
</style>
